<template>
  <div class="format-language">
    <v-select
      v-model="selectedLanguage"
      :items="languages"
      dense
      :menu-props="menuProps"
      class="custom-dropdown-language-crm"
      hide-details
      solo-inverted
      color="#f6f6f6"
    >
      <template v-slot:selection="{ item }">
        <v-img
          :src="require(`@/assets/img/imageLanguage/${item.image}`)"
          :alt="item.text"
          width="30"
          height="16"
        ></v-img>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item link class="list-language" @click="changeLanguage(item)">
          <v-img
            :src="require(`@/assets/img/imageLanguage/${item.image}`)"
            :alt="item.text"
            width="30"
            height="16"
          ></v-img>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      selectedLanguage: 'fr',
      languages: [
        { value: 'fr', text: 'Français', image: 'image_fr.png' },
        { value: 'en', text: 'English', image: 'image_en.png' }
        // Ajoutez d'autres langues avec leurs drapeaux ici
      ],
      menuProps: {
        offsetY: true,
        nudgeBottom: 3,
        nudgeLeft: 0,
        closeOnContentClick: true
      }
    }
  },
  methods: {
    ...mapActions([]),
    changeLanguage(item) {
      // Logique pour changer la langue
      this.selectedLanguage = item.value
      // Vous pouvez émettre un événement ou appeler une méthode pour changer la langue de l'application ici
      this.$emit('langage', item.value)
      localStorage.setItem('i18n', item.value)
    }
  },
  mounted() {
    if (localStorage.getItem('i18n')) {
      this.selectedLanguage = localStorage.getItem('i18n')
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  components: {}
}
</script>

<style lang="scss">
.custom-dropdown-language-crm {
  .v-input--is-focused .v-input__control .v-input__slot::before {
    border-color: transparent !important; /* Bordure transparente */
  }
  .v-select__selections {
    /* Masquer la flèche du dropdown */
    border-right: none !important;
    input {
      display: none !important;
    }
  }
  .v-input__append-inner {
    display: none;
  }
  .v-input__slot {
    border: none !important;
    background-color: rgb(246, 246, 246) !important;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    box-shadow: unset !important;
    padding: 4px !important;
    min-height: 26px !important;
  }
  .v-input__control {
    min-height: 27px !important;
    background-color: rgb(246, 246, 246) !important;
  }
  .v-menu__content {
    &.menuable__content__active {
      /* Modifie la largeur du menu */
      min-width: 30px !important; /* Largeur personnalisée */
      max-width: 30px !important; /* Largeur maximale personnalisée */
    }
  }
}
.list-language {
  min-height: 25px !important;
  padding: 0px 5px !important;
}
.v-select-list {
  padding: 0px 0px !important;
}
.format-language {
  .theme--light.v-text-field--solo-inverted.v-input--is-focused
    > .v-input__control
    > .v-input__slot {
    border: 1px solid rgba(47, 47, 47, 0.1) !important;
    background-color: rgb(246, 246, 246) !important;
  }
}
</style>
